.flex {
    display: flex;
}
.flex-1 {
    min-height: 0;
}

.flex-1 {
    flex: 1;
}


.bold{
    font-weight: bold;
}


.TextBlock-header {
    margin-bottom: .8em
}

.TextBlock-header + .HubBlock-content {
    margin-top: .8em
}

.TextBlock-header-token {
    margin-right: .6em;
    font-size: 2.5em;
    text-transform: uppercase;
    font-weight: 800;
    line-height: 1em
}

.TextBlock-header-title {
    font-weight: 600;
    margin: 0;
    line-height: 1em;
    font-size: 2.1em
}

.TextBlock-header-subtitle {
    color: #000;
    color: rgba(0, 0, 0, .6);
    font-size: 1em;
    margin-bottom: .4em;
    margin-left: .1em
}








.markdown-body {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    font-size: 1.2em
}

.markdown-body s {
    text-decoration: line-through
}

.markdown-body .octicon {
    display: inline-block;
    vertical-align: text-top;
    fill: currentColor
}

.markdown-body a {
    background-color: initial;
    -webkit-text-decoration-skip: objects
}

.markdown-body a:active, .markdown-body a:hover {
    outline-width: 0
}

.markdown-body strong {
    font-weight: inherit;
    font-weight: 800
}

.markdown-body svg:not(:root) {
    overflow: hidden
}

.markdown-body [type=checkbox] {
    box-sizing: border-box;
    padding: 0
}

.markdown-body * {
    box-sizing: border-box
}

.markdown-body input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible
}

.markdown-body a {
    color: #0366d6;
    text-decoration: none
}

.HtmlViewer a:hover:not(.anchor), .markdown-body a:hover:not(.anchor) {
    text-decoration: underline
}

.markdown-body strong {
    font-weight: 600
}

.markdown-body hr {
    height: .25em;
    padding: 0;
    margin: 2.3em 0;
    background-color: #dae1e7;
    border: 0;
    box-sizing: initial;
    overflow: visible
}

.markdown-body hr:after, .markdown-body hr:before {
    display: table;
    content: ""
}

.markdown-body hr:after {
    clear: both
}

.markdown-body table {
    border-spacing: 0;
    border-collapse: collapse
}

.markdown-body td, .markdown-body th {
    padding: 0;
    text-align: left;
    vertical-align: top
}

.markdown-body p {
    margin-top: 0;
    margin-bottom: .8em;
    line-height: 1.6
}

.markdown-body p:last-child {
    margin-bottom: 0
}

.markdown-body li code, .markdown-body p code {
    color: #e74c3c
}

.markdown-body blockquote {
    margin: 0;
    line-height: 1.6
}

.markdown-body ol, .markdown-body ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.35
}

.markdown-body ol ol, .markdown-body ul ol {
    list-style-type: lower-roman
}

.markdown-body ol ol ol, .markdown-body ol ul ol, .markdown-body ul ol ol, .markdown-body ul ul ol {
    list-style-type: lower-alpha
}

.markdown-body dd {
    margin-left: 0
}

.markdown-body:after, .markdown-body:before {
    display: table;
    content: ""
}

.markdown-body:after {
    clear: both
}

.markdown-body > :first-child {
    margin-top: 0 !important
}

.markdown-body > :last-child {
    margin-bottom: 0 !important
}

.markdown-body a:not([href]) {
    color: inherit;
    text-decoration: none
}






.JSV-row:nth-child(2n) {
    background-color: #f8fafc;
}






.items-baseline {
    align-items: baseline;
}
.mr-3 {
    margin-right: 10px;
}
.sl-schema-colors .sl--string, .u-schemaColors .sl--string {
    color: teal;
}
.text-sm {
    font-size: 11px;
}
.text-muted {
    color: rgba(19,15,33,.6);
}





.token--get {
    color: #4eb509
}

.token--post {
    color: #5e98cd
}

.token--put {
    color: #786b6d
}

.token--patch {
    color: #948789
}

.token--delete {
    color: #c0392b
}

.token--copy {
    color: #7eadd7
}

.token--head {
    color: #68f20c
}

.token--options {
    color: #4eb509 20%
}

.token--link {
    color: #3b99fc
}

.token--unlink {
    color: #62adfd
}

.token--purge {
    color: #d7584b
}

.token--lock {
    color: #aaa
}

.token--unlock {
    color: #ccc
}


.sl-jse_error {
    padding: 10px 20px;
    border-top: 1px solid #ddd;
    color: #e74c3c
}

.sl-jse_error i {
    margin-right: 10px
}

.sl-jse_error.sl--example {
    border-top: none;
    border-bottom: 1px solid #ddd
}

.sl-jse_toggle {
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    font-size: 13px
}

.sl-jse_toggle > div {
    padding: 6px 10px;
    border-radius: 2px;
    cursor: pointer;
    position: relative
}

.sl-jse_toggle > div:hover {
    background-color: #f7f9fa;
    color: #3b99fc
}

.sl-jse_toggle > div.on {
    background-color: #3b99fc;
    color: #fff;
    cursor: default
}

.sl-jse_gui {
    margin-left: -1px
}

.sl-jse_gui_rows {
    z-index: 5;
    padding: 20px 0;
    width: 100%
}

.sl-jse_gui_gutter {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 59;
    z-index: 4;
    border-right: 1px solid #f1f1f1
}

.sl-jse_row {
    position: relative;
    font-size: 13px
}

.sl-jse_row input[type=email], .sl-jse_row input[type=number], .sl-jse_row input[type=password], .sl-jse_row input[type=text], .sl-jse_row input[type=url] {
    background-color: initial;
    padding: 2px 4px;
    font-size: 13px;
    border: none
}

.sl-jse_row input[type=email]:focus, .sl-jse_row input[type=email]:hover, .sl-jse_row input[type=number]:focus, .sl-jse_row input[type=number]:hover, .sl-jse_row input[type=password]:focus, .sl-jse_row input[type=password]:hover, .sl-jse_row input[type=text]:focus, .sl-jse_row input[type=text]:hover, .sl-jse_row input[type=url]:focus, .sl-jse_row input[type=url]:hover {
    background-color: #3b99fc;
    background-color: rgba(59, 153, 252, .15)
}

.sl-jse_row:hover {
    background-color: #3b99fc;
    background-color: rgba(59, 153, 252, .06)
}

.sl-jse_row.sl--0 .sl--remove {
    display: none
}

.sl-jse_row.sl--open, .sl-jse_row.sl--open:hover {
    background-color: #fff
}

.sl-jse_row.sl--open .sl-jse_row_meta_item.sl--remove {
    visibility: hidden
}

.sl-jse_row.sl--open .sl-jse_row_add, .sl-jse_row.sl--open .sl-jse_row_collapse-handle_inner {
    display: none
}

.sl-jse_row.sl--open .sl-jse_row_inner {
    background-color: #3b99fc;
    color: #fff !important
}

.sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_meta_item, .sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_meta_item a, .sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_type, .sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_type a, .sl-jse_row.sl--open .sl-jse_row_inner i, .sl-jse_row.sl--open .sl-jse_row_inner input, .sl-jse_row.sl--open .sl-jse_row_inner input a {
    color: #fff !important
}

.sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_meta_item::-webkit-input-placeholder, .sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_type::-webkit-input-placeholder, .sl-jse_row.sl--open .sl-jse_row_inner input::-webkit-input-placeholder {
    color: #fff !important;
    color: hsla(0, 0%, 100%, .6) !important
}

.sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_meta_item > *, .sl-jse_row.sl--open .sl-jse_row_inner .sl-jse_row_type > *, .sl-jse_row.sl--open .sl-jse_row_inner input > * {
    color: #fff !important
}

.sl-jse_row_inner {
    padding: 4px 0;
    position: relative
}

.sl-jse_row_add {
    position: absolute;
    top: 4px;
    left: 28px;
    width: 22px;
    height: 22px;
    font-size: 13px;
    z-index: 5
}

.sl-jse_row_add > i {
    position: relative;
    opacity: .3
}

.sl-jse_row_add.on, .sl-jse_row_add:hover {
    color: #e040fb;
    background-color: #000;
    background-color: rgba(0, 0, 0, .05);
    cursor: pointer
}

.sl-jse_row_add.on i, .sl-jse_row_add:hover i {
    z-index: 10;
    opacity: 1
}

.sl-jse_row_details_apply, .sl-jse_row_details_cancel, .sl-jse_row_details_handle {
    position: absolute;
    top: 4px;
    left: 5px;
    height: 22px;
    width: 22px;
    font-size: 12px;
    opacity: .3;
    z-index: 5
}

.sl-jse_row_details_apply:hover, .sl-jse_row_details_cancel:hover, .sl-jse_row_details_handle:hover {
    color: #e040fb;
    background-color: #000;
    background-color: rgba(0, 0, 0, .05);
    cursor: pointer;
    z-index: 10;
    opacity: 1
}

.sl-jse_row_details_apply.sl--disabled, .sl-jse_row_details_cancel.sl--disabled, .sl-jse_row_details_handle.sl--disabled {
    cursor: not-allowed;
    opacity: .15
}

.sl-jse_row_details_handle.is-active {
    color: #3b99fc;
    opacity: .75
}

.sl-jse_row_details_cancel:hover {
    background-color: #fff;
    color: #e74c3c
}

.sl-jse_row_details_apply {
    opacity: 1;
    color: #fff;
    background-color: #000;
    background-color: rgba(0, 0, 0, .05)
}

.sl-jse_row_details_apply:hover {
    color: #e040fb;
    background-color: #fff
}

.sl-jse_row_meta_item {
    font-size: 12px;
    color: #999;
    padding-right: 20px;
    position: relative;
    top: -1px;
    -moz-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: default
}

.sl-jse_row_meta_item > .fa {
    margin-right: 3px;
    font-size: .8em;
    position: relative;
    top: 1px
}

.sl-jse_row_meta_item.is-clickable {
    cursor: pointer
}

.sl-jse_row_meta_item.is-clickable .fa {
    opacity: .5
}

.sl-jse_row_meta_item.is-clickable:hover {
    text-decoration: underline
}

.sl-jse_row_meta_item.is-clickable:hover .fa, .sl-jse_row_meta_item.is-on .fa {
    opacity: 1
}

.sl-jse_row_meta_item.sl--remove {
    cursor: pointer;
    width: 24px;
    top: -1px
}

.sl-jse_row_meta_item.sl--remove i {
    margin-right: 0;
    font-size: 1em;
    top: 0
}

.sl-jse_row_meta_item.sl--remove:hover {
    color: #e74c3c
}

.sl-jse_row_basics {
    max-height: 500px;
    overflow: scroll
}

.sl-jse_row_collapse-handle {
    position: relative
}

.sl-jse_row_collapse-handle_inner {
    width: 22px;
    font-size: 13px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -22px
}

.sl-jse_row_collapse-handle_inner:hover {
    background-color: #000;
    background-color: rgba(0, 0, 0, .05);
    color: #3b99fc;
    cursor: pointer
}

.sl-jse_name input {
    font-size: 13px
}

.sl-jse_name.sl--disabled input {
    opacity: .75;
    border: none
}

.sl-jse_name.sl--disabled input:hover {
    background-color: initial
}

.sl-jse_row_child-count {
    font-size: .8em;
    opacity: .5
}

.sl-jse_row_types {
    padding: 2px 0 2px 4px;
    position: relative
}

.sl-jse_row_types > .sl--spacer {
    margin: 0 10px 0 1px
}

.sl-jse_row_types .sl-jse_row_type:hover {
    cursor: pointer;
    text-decoration: underline
}

.sl-jse_row_type .sl--or {
    color: #999;
    padding: 0 5px;
    font-size: .8em
}

.sl-jse_row_type .sl--spacer {
    margin: 0 10px
}

.sl-jse_row_meta_item .JSE-selector {
    left: auto;
    right: 0
}

.sl-jse_row_meta_item .JSE-selectorCaret {
    right: 20px;
    left: auto
}

.sl-jse_row_details {
    background-color: #3b99fc;
    background-color: rgba(59, 153, 252, .02);
    padding-bottom: 6px;
    border-bottom: 2px solid #3b99fc;
    font-size: 12px
}

.sl-jse_row_details input, .sl-jse_row_details select {
    font-size: 12px
}

.sl-jse_row_details_actions {
    padding: 20px;
    border-bottom: 1px solid #f1f5f8
}

.sl-jse_row_details_actions .btn {
    margin-right: 10px
}

.sl-jse_row_details_column {
    width: 33%;
    padding: 10px
}

.sl-jse_row_details_column + .sl-jse_row_details_column {
    border-left: 1px solid #f1f5f8
}

.sl-jse_row_details_section {
    min-width: 500px;
    padding-bottom: 15px
}

.sl-jse_row_details_section .RefBuilder {
    margin-top: 5px
}

.sl-jse_row_details_section:last-child {
    padding-bottom: 0
}

.sl-jse_row_details_section_name {
    text-transform: uppercase;
    font-weight: 800;
    font-size: .8em
}

.sl-jse_row_details_section_items {
    padding-top: 2px;
    flex-wrap: wrap
}

.sl-jse_row_details_section_items.sl--box-list {
    margin-left: -7px
}

.sl-jse_row_details_section_item {
    padding: 1px 3px;
    border-radius: 2px;
    margin: 2px 0 2px 5px
}

.sl-jse_row_details_section_item:hover {
    background-color: #f1f5f8;
    cursor: pointer
}

.sl-jse_row_details_section_item.on {
    background-color: red
}

.sl-jse_row_details_line_items {
    padding-top: 10px
}

.sl-jse_row_details_line_item {
    padding-bottom: 10px
}

.sl-jse_row_details_line_item .sl--name + .sl--input {
    padding-left: 6px
}

.sl-jse_row_details_line_item .sl--input {
    width: 100%
}

.sl-jse_row_details_line_item input[type=email], .sl-jse_row_details_line_item input[type=number], .sl-jse_row_details_line_item input[type=password], .sl-jse_row_details_line_item input[type=text], .sl-jse_row_details_line_item input[type=url], .sl-jse_row_details_line_item textarea {
    width: 100%;
    padding: 9px;
    border: 1px solid #ddd;
    border-radius: 2px;
    outline: none
}

.sl-jse_row_details_line_item input[type=email]:focus, .sl-jse_row_details_line_item input[type=number]:focus, .sl-jse_row_details_line_item input[type=password]:focus, .sl-jse_row_details_line_item input[type=text]:focus, .sl-jse_row_details_line_item input[type=url]:focus, .sl-jse_row_details_line_item textarea:focus {
    border-color: #3b99fc
}

.sl-jse_row_details_line_item textarea {
    margin-top: 6px;
    padding: 2px 6px;
    background-color: #f1f8ff;
    position: relative;
    z-index: 1;
    resize: none
}

.sl-jse_row_details_line_item:last-of-type {
    padding-bottom: 0
}

.sl-jse_row_details_line_item_column {
    margin-left: 6px
}

.sl-jse_row_details_line_item_column:first-of-type {
    margin-left: 0;
    border-bottom: none
}

.sl-jse_row_details_line_item_column:first-of-type .sl-checkbox {
    margin-left: -6px
}

.sl-jse_example_status {
    padding: 6px 15px;
    background-color: #fff;
    border-top: 1px solid #f1f5f8;
    font-size: 13px;
    position: relative;
    z-index: 5
}

.sl-jse_example_status i {
    padding-right: 8px
}

.sl-jse_example_status.sl--positive {
    color: #4eb509
}

.sl-jse_example_status.sl--negative {
    color: #e74c3c
}

.sl-jse_example_tab-bar {
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    z-index: 8
}

.sl-jse_example_tabs {
    display: flex;
    font-size: 12px
}

.sl-jse_example_tab {
    cursor: pointer;
    text-transform: capitalize;
    height: 28px;
    padding: 0 15px;
    border-right: 1px solid;
    border-color: #e7e7e7;
    position: relative;
    z-index: 5
}

.sl-jse_example_tab .sl--bg {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: -1px;
    display: none;
    z-index: -1
}

.sl-jse_example_tab:hover {
    color: #3b99fc
}

.sl-jse_example_tab.on {
    cursor: default;
    background: #fff;
    color: #3b99fc
}

.sl-jse_example_tab.on .sl--bg {
    display: block
}

.sl-jse_example {
    border: 1px solid #f1f5f8
}

.sl-jse_example .sl-panel_header {
    background-color: #f9fbfb;
    padding: 0
}

.sl-jse_example .sl-panel_header input {
    height: 28px
}

.sl-jse_example .sl--content-type {
    height: 28px;
    display: flex;
    align-items: center;
    padding: 0 10px
}

.sl-jse_example .sl--label {
    height: 28px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    color: #999;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 700;
    padding-left: 20px;
    padding-right: 10px
}

.sl-jse_example .sl--label:first-of-type {
    border-left: none
}

.sl-jse_example .sl--label:last-of-type {
    border-right: none
}

.sl-jse_example .sl--not-supported {
    padding: 30px
}

.sl-jse_example_button {
    padding-top: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 13px;
    border-bottom: 1px solid #e7e7e7;
    width: 100%
}

.sl-schema-colors, .u-schemaColors {
    position: relative
}

.sl-schema-colors .sl--noType, .sl-schema-colors .u-none, .u-schemaColors .sl--noType, .u-schemaColors .u-none {
    color: #999
}

.sl-schema-colors .sl--noType.on, .sl-schema-colors .u-none.on, .u-schemaColors .sl--noType.on, .u-schemaColors .u-none.on {
    color: #fff;
    background-color: #789
}

.sl-schema-colors .sl--object, .u-schemaColors .sl--object {
    color: #00f
}

.sl-schema-colors .sl--object.on, .u-schemaColors .sl--object.on {
    color: #fff;
    background-color: #00f
}

.sl-schema-colors .sl--array, .u-schemaColors .sl--array {
    color: green
}

.sl-schema-colors .sl--array.on, .u-schemaColors .sl--array.on {
    color: #fff;
    background-color: green
}

.sl-schema-colors .sl--allOf, .sl-schema-colors .sl--anyOf, .sl-schema-colors .sl--oneOf, .u-schemaColors .sl--allOf, .u-schemaColors .sl--anyOf, .u-schemaColors .sl--oneOf {
    color: #b8860b
}

.sl-schema-colors .sl--allOf.on, .sl-schema-colors .sl--anyOf.on, .sl-schema-colors .sl--oneOf.on, .u-schemaColors .sl--allOf.on, .u-schemaColors .sl--anyOf.on, .u-schemaColors .sl--oneOf.on {
    color: #fff;
    background-color: #b8860b
}

.sl-schema-colors .sl--null, .u-schemaColors .sl--null {
    color: coral
}

.sl-schema-colors .sl--null.on, .u-schemaColors .sl--null.on {
    color: #fff;
    background-color: coral
}

.sl-schema-colors .sl--string, .u-schemaColors .sl--string {
    color: teal
}

.sl-schema-colors .sl--string.on, .u-schemaColors .sl--string.on {
    color: #fff;
    background-color: teal
}

.sl-schema-colors .sl--integer, .sl-schema-colors .sl--number, .u-schemaColors .sl--integer, .u-schemaColors .sl--number {
    color: brown
}

.sl-schema-colors .sl--integer.on, .sl-schema-colors .sl--number.on, .u-schemaColors .sl--integer.on, .u-schemaColors .sl--number.on {
    color: #fff;
    background-color: brown
}

.sl-schema-colors .sl--boolean, .u-schemaColors .sl--boolean {
    color: #ff69b4
}

.sl-schema-colors .sl--boolean.on, .u-schemaColors .sl--boolean.on {
    color: #fff;
    background-color: #ff69b4
}

.sl-schema-colors .sl--binary, .sl-schema-colors .sl--file, .u-schemaColors .sl--binary, .u-schemaColors .sl--file {
    color: #66cdaa
}

.sl-schema-colors .sl--binary.on, .sl-schema-colors .sl--file.on, .u-schemaColors .sl--binary.on, .u-schemaColors .sl--file.on {
    color: #fff;
    background-color: #66cdaa
}

.sl-schema-colors .sl--ref, .u-schemaColors .sl--ref {
    color: #8a2be2
}

.sl-schema-colors .sl--ref.on, .u-schemaColors .sl--ref.on {
    color: #fff;
    background-color: #8a2be2
}






.JSV-row {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.JSV-row:nth-child(2n) {
    background-color: #f8fafc
}

.JSV-row:hover {
    background-color: #f3f3f3
}

.JSV-row i {
    font-size: 11px
}

.JSV-row--0 {
    padding-left: 20px
}

.JSV-divider--0 {
    padding-left: 5px
}

.JSV-row--1 {
    padding-left: 35px
}

.JSV-divider--1 {
    padding-left: 20px
}

.JSV-row--2 {
    padding-left: 50px
}

.JSV-divider--2 {
    padding-left: 35px
}

.JSV-row--3 {
    padding-left: 65px
}

.JSV-divider--3 {
    padding-left: 50px
}

.JSV-row--4 {
    padding-left: 80px
}

.JSV-divider--4 {
    padding-left: 65px
}

.JSV-row--5 {
    padding-left: 95px
}

.JSV-divider--5 {
    padding-left: 80px
}

.JSV-row--6 {
    padding-left: 110px
}

.JSV-divider--6 {
    padding-left: 95px
}

.JSV-row--7 {
    padding-left: 125px
}

.JSV-divider--7 {
    padding-left: 110px
}

.JSV-row--8 {
    padding-left: 140px
}

.JSV-divider--8 {
    padding-left: 125px
}

.JSV-row--9 {
    padding-left: 155px
}

.JSV-divider--9 {
    padding-left: 140px
}

.JSV-row--10 {
    padding-left: 170px
}

.JSV-divider--10 {
    padding-left: 155px
}

.JSV-row--11 {
    padding-left: 185px
}

.JSV-divider--11 {
    padding-left: 170px
}

.JSV-row--12 {
    padding-left: 200px
}

.JSV-divider--12 {
    padding-left: 185px
}

.JSV-row--13 {
    padding-left: 215px
}

.JSV-divider--13 {
    padding-left: 200px
}

.JSV-row--14 {
    padding-left: 230px
}

.JSV-divider--14 {
    padding-left: 215px
}

.JSV-row--15 {
    padding-left: 245px
}

.JSV-divider--15 {
    padding-left: 230px
}

.JSV-row--16 {
    padding-left: 260px
}

.JSV-divider--16 {
    padding-left: 245px
}

.JSV-row--17 {
    padding-left: 275px
}

.JSV-divider--17 {
    padding-left: 260px
}

.JSV-row--18 {
    padding-left: 290px
}

.JSV-divider--18 {
    padding-left: 275px
}

.JSV-row--19 {
    padding-left: 305px
}

.JSV-divider--19 {
    padding-left: 290px
}

.JSV-row--20 {
    padding-left: 320px
}

.JSV-divider--20 {
    padding-left: 305px
}

.JSV-row--21 {
    padding-left: 335px
}

.JSV-divider--21 {
    padding-left: 320px
}

.JSV-row--22 {
    padding-left: 350px
}

.JSV-divider--22 {
    padding-left: 335px
}

.JSV-row--23 {
    padding-left: 365px
}

.JSV-divider--23 {
    padding-left: 350px
}

.JSV-row--24 {
    padding-left: 380px
}

.JSV-divider--24 {
    padding-left: 365px
}

.JSV-row--25 {
    padding-left: 395px
}

.JSV-divider--25 {
    padding-left: 380px
}

.JSV-row--26 {
    padding-left: 410px
}

.JSV-divider--26 {
    padding-left: 395px
}

.JSV-row--27 {
    padding-left: 425px
}

.JSV-divider--27 {
    padding-left: 410px
}

.JSV-row--28 {
    padding-left: 440px
}

.JSV-divider--28 {
    padding-left: 425px
}

.JSV-row--29 {
    padding-left: 455px
}

.JSV-divider--29 {
    padding-left: 440px
}

.JSV-row--30 {
    padding-left: 470px
}

.JSV-divider--30 {
    padding-left: 455px
}

.JSV-row--31 {
    padding-left: 485px
}

.JSV-divider--31 {
    padding-left: 470px
}

.JSV-row--32 {
    padding-left: 500px
}

.JSV-divider--32 {
    padding-left: 485px
}

.JSV-row--33 {
    padding-left: 515px
}

.JSV-divider--33 {
    padding-left: 500px
}

.JSV-row--34 {
    padding-left: 530px
}

.JSV-divider--34 {
    padding-left: 515px
}

.JSV-row--35 {
    padding-left: 545px
}

.JSV-divider--35 {
    padding-left: 530px
}

.JSV-row--36 {
    padding-left: 560px
}

.JSV-divider--36 {
    padding-left: 545px
}

.JSV-row--37 {
    padding-left: 575px
}

.JSV-divider--37 {
    padding-left: 560px
}

.JSV-row--38 {
    padding-left: 590px
}

.JSV-divider--38 {
    padding-left: 575px
}

.JSV-row--39 {
    padding-left: 605px
}

.JSV-divider--39 {
    padding-left: 590px
}

.JSV-row--40 {
    padding-left: 620px
}

.JSV-divider--40 {
    padding-left: 605px
}

.JSV-row--41 {
    padding-left: 635px
}

.JSV-divider--41 {
    padding-left: 620px
}

.JSV-row--42 {
    padding-left: 650px
}

.JSV-divider--42 {
    padding-left: 635px
}

.JSV-row--43 {
    padding-left: 665px
}

.JSV-divider--43 {
    padding-left: 650px
}

.JSV-row--44 {
    padding-left: 680px
}

.JSV-divider--44 {
    padding-left: 665px
}

.JSV-row--45 {
    padding-left: 695px
}

.JSV-divider--45 {
    padding-left: 680px
}

.JSV-row--46 {
    padding-left: 710px
}

.JSV-divider--46 {
    padding-left: 695px
}

.JSV-row--47 {
    padding-left: 725px
}

.JSV-divider--47 {
    padding-left: 710px
}

.JSV-row--48 {
    padding-left: 740px
}

.JSV-divider--48 {
    padding-left: 725px
}

.JSV-row--49 {
    padding-left: 755px
}

.JSV-divider--49 {
    padding-left: 740px
}

.JSV-row--50 {
    padding-left: 770px
}

.JSV-divider--50 {
    padding-left: 755px
}

.JSV-rowExpander {
    padding-left: .5rem
}

.JSV-rowExpander, .JSV-validations {
    margin-right: .5rem
}

.JSV-dividerInner {
    height: 1px;
    background-color: #ddd;
    position: relative
}

.JSV-dividerText {
    position: absolute;
    left: -5px;
    top: -10px;
    padding: 0 15px;
    background-color: #fff
}

.JSV-copyToClipboard {
    position: absolute;
    top: 0;
    right: 0
}