.woocommerce-terms-and-conditions ol{
    list-style-type:none !important;
}

.woocommerce-terms-and-conditions .lst-kix_list_4-1 > li {
    counter-increment: lst-ctn-kix_list_4-1
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-1 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-2 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_3-1 > li {
    counter-increment: lst-ctn-kix_list_3-1
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-3 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-4.start {
    counter-reset: lst-ctn-kix_list_3-4 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-4 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_2-1 > li {
    counter-increment: lst-ctn-kix_list_2-1
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-0 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_1-1 > li {
    counter-increment: lst-ctn-kix_list_1-1
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-6.start {
    counter-reset: lst-ctn-kix_list_2-6 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-0 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) ". "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-1.start {
    counter-reset: lst-ctn-kix_list_3-1 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-1 > li:before {
    content: "" counter(lst-ctn-kix_list_3-0, decimal) "." counter(lst-ctn-kix_list_3-1, decimal) " "
}

.woocommerce-terms-and-conditions .lst-kix_list_3-2 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-2, lower-latin) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-8.start {
    counter-reset: lst-ctn-kix_list_1-8 0
}

.woocommerce-terms-and-conditions .lst-kix_list_4-0 > li {
    counter-increment: lst-ctn-kix_list_4-0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-3.start {
    counter-reset: lst-ctn-kix_list_2-3 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-5 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-5, decimal) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_3-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-4, upper-latin) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-5.start {
    counter-reset: lst-ctn-kix_list_1-5 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_3-3, lower-roman) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-5 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-6 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-7 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-8 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_3-8 > li:before {
    content: "" counter(lst-ctn-kix_list_3-8, lower-roman) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-0 > li {
    counter-increment: lst-ctn-kix_list_2-0
}

.woocommerce-terms-and-conditions .lst-kix_list_2-3 > li {
    counter-increment: lst-ctn-kix_list_2-3
}

.woocommerce-terms-and-conditions .lst-kix_list_3-6 > li:before {
    content: "" counter(lst-ctn-kix_list_3-6, decimal) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-3 > li {
    counter-increment: lst-ctn-kix_list_4-3
}

.woocommerce-terms-and-conditions .lst-kix_list_3-7 > li:before {
    content: "" counter(lst-ctn-kix_list_3-7, lower-latin) ". "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-5.start {
    counter-reset: lst-ctn-kix_list_4-5 0
}

.woocommerce-terms-and-conditions .lst-kix_list_1-2 > li {
    counter-increment: lst-ctn-kix_list_1-2
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-7.start {
    counter-reset: lst-ctn-kix_list_3-7 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-2.start {
    counter-reset: lst-ctn-kix_list_4-2 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-2 > li {
    counter-increment: lst-ctn-kix_list_3-2
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-2 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-3 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-4 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-5 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_1-4 > li {
    counter-increment: lst-ctn-kix_list_1-4
}

.woocommerce-terms-and-conditions .lst-kix_list_4-4 > li {
    counter-increment: lst-ctn-kix_list_4-4
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-0 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-6.start {
    counter-reset: lst-ctn-kix_list_1-6 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-1 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_4-8 > li:before {
    content: "" counter(lst-ctn-kix_list_4-8, lower-roman) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-7 > li:before {
    content: "" counter(lst-ctn-kix_list_4-7, lower-latin) ". "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-1.start {
    counter-reset: lst-ctn-kix_list_4-1 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-8.start {
    counter-reset: lst-ctn-kix_list_4-8 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-3.start {
    counter-reset: lst-ctn-kix_list_3-3 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-6 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-7 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-8 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-0.start {
    counter-reset: lst-ctn-kix_list_1-0 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-0 > li {
    counter-increment: lst-ctn-kix_list_3-0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-3 > li {
    counter-increment: lst-ctn-kix_list_3-3
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-0.start {
    counter-reset: lst-ctn-kix_list_4-0 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-6 > li {
    counter-increment: lst-ctn-kix_list_3-6
}

.woocommerce-terms-and-conditions .lst-kix_list_2-5 > li {
    counter-increment: lst-ctn-kix_list_2-5
}

.woocommerce-terms-and-conditions .lst-kix_list_2-8 > li {
    counter-increment: lst-ctn-kix_list_2-8
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-2.start {
    counter-reset: lst-ctn-kix_list_3-2 0
}

.woocommerce-terms-and-conditions .lst-kix_list_2-2 > li {
    counter-increment: lst-ctn-kix_list_2-2
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-4.start {
    counter-reset: lst-ctn-kix_list_2-4 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-7.start {
    counter-reset: lst-ctn-kix_list_4-7 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-3 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-4 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_2-6 > li:before {
    content: "" counter(lst-ctn-kix_list_2-6, decimal) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-7 > li:before {
    content: "" counter(lst-ctn-kix_list_2-7, lower-latin) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-7 > li {
    counter-increment: lst-ctn-kix_list_2-7
}

.woocommerce-terms-and-conditions .lst-kix_list_3-7 > li {
    counter-increment: lst-ctn-kix_list_3-7
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-5 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-6 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-0 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_2-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-4, lower-latin) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-5 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-5, lower-roman) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-8 > li:before {
    content: "" counter(lst-ctn-kix_list_2-8, lower-roman) ". "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-1 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-2 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-6.start {
    counter-reset: lst-ctn-kix_list_4-6 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-0.start {
    counter-reset: lst-ctn-kix_list_3-0 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-3.start {
    counter-reset: lst-ctn-kix_list_4-3 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-7 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_4-7 > li {
    counter-increment: lst-ctn-kix_list_4-7
}

.woocommerce-terms-and-conditions .lst-kix_list_1-7 > li {
    counter-increment: lst-ctn-kix_list_1-7
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-8 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-8.start {
    counter-reset: lst-ctn-kix_list_3-8 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-5.start {
    counter-reset: lst-ctn-kix_list_2-5 0
}

.woocommerce-terms-and-conditions li.li-bullet-1:before {
    margin-left: -35.5pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 35.5pt
}

.woocommerce-terms-and-conditions .lst-kix_list_4-0 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-6 > li {
    counter-increment: lst-ctn-kix_list_2-6
}

.woocommerce-terms-and-conditions .lst-kix_list_3-8 > li {
    counter-increment: lst-ctn-kix_list_3-8
}

.woocommerce-terms-and-conditions .lst-kix_list_4-1 > li:before {
    content: "" counter(lst-ctn-kix_list_4-0, decimal) "." counter(lst-ctn-kix_list_4-1, decimal) " "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-6 > li {
    counter-increment: lst-ctn-kix_list_4-6
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-7.start {
    counter-reset: lst-ctn-kix_list_1-7 0
}

.woocommerce-terms-and-conditions .lst-kix_list_4-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-4, upper-latin) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-2.start {
    counter-reset: lst-ctn-kix_list_2-2 0
}

.woocommerce-terms-and-conditions .lst-kix_list_1-5 > li {
    counter-increment: lst-ctn-kix_list_1-5
}

.woocommerce-terms-and-conditions .lst-kix_list_4-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-3, lower-roman) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-5 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-5, decimal) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-2 > li:before {
    content: "(" counter(lst-ctn-kix_list_4-2, lower-latin) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-6 > li:before {
    content: "" counter(lst-ctn-kix_list_4-6, decimal) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_1-8 > li {
    counter-increment: lst-ctn-kix_list_1-8
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-4.start {
    counter-reset: lst-ctn-kix_list_1-4 0
}

.woocommerce-terms-and-conditions .lst-kix_list_3-5 > li {
    counter-increment: lst-ctn-kix_list_3-5
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-1.start {
    counter-reset: lst-ctn-kix_list_1-1 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-0 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_3-4 > li {
    counter-increment: lst-ctn-kix_list_3-4
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-1 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-4.start {
    counter-reset: lst-ctn-kix_list_4-4 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-2 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-3 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_2-4 > li {
    counter-increment: lst-ctn-kix_list_2-4
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-6.start {
    counter-reset: lst-ctn-kix_list_3-6 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-3.start {
    counter-reset: lst-ctn-kix_list_1-3 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-8.start {
    counter-reset: lst-ctn-kix_list_2-8 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_1-2.start {
    counter-reset: lst-ctn-kix_list_1-2 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-8 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_1-0 > li:before {
    content: "" counter(lst-ctn-kix_list_1-0, decimal) " "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-4 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-5 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_1-1 > li:before {
    content: "" counter(lst-ctn-kix_list_1-1, decimal) " "
}

.woocommerce-terms-and-conditions .lst-kix_list_1-2 > li:before {
    content: "" counter(lst-ctn-kix_list_1-2, lower-roman) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-0.start {
    counter-reset: lst-ctn-kix_list_2-0 0
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-6 {
    list-style-type: none
}

.woocommerce-terms-and-conditions ol.lst-kix_list_4-7 {
    list-style-type: none
}

.woocommerce-terms-and-conditions .lst-kix_list_1-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-3, decimal) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_1-4 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-4, lower-latin) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_3-5.start {
    counter-reset: lst-ctn-kix_list_3-5 0
}

.woocommerce-terms-and-conditions .lst-kix_list_1-0 > li {
    counter-increment: lst-ctn-kix_list_1-0
}

.woocommerce-terms-and-conditions .lst-kix_list_4-8 > li {
    counter-increment: lst-ctn-kix_list_4-8
}

.woocommerce-terms-and-conditions .lst-kix_list_1-6 > li {
    counter-increment: lst-ctn-kix_list_1-6
}

.woocommerce-terms-and-conditions .lst-kix_list_1-7 > li:before {
    content: "" counter(lst-ctn-kix_list_1-7, lower-latin) ". "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-7.start {
    counter-reset: lst-ctn-kix_list_2-7 0
}

.woocommerce-terms-and-conditions .lst-kix_list_1-3 > li {
    counter-increment: lst-ctn-kix_list_1-3
}

.woocommerce-terms-and-conditions .lst-kix_list_1-5 > li:before {
    content: "(" counter(lst-ctn-kix_list_1-5, lower-roman) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_1-6 > li:before {
    content: "" counter(lst-ctn-kix_list_1-6, decimal) ". "
}

.woocommerce-terms-and-conditions li.li-bullet-0:before {
    margin-left: -28.4pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 28.4pt
}

.woocommerce-terms-and-conditions .lst-kix_list_2-0 > li:before {
    content: "" counter(lst-ctn-kix_list_2-0, decimal) " "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-1 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-1, lower-latin) ") "
}

.woocommerce-terms-and-conditions ol.lst-kix_list_2-1.start {
    counter-reset: lst-ctn-kix_list_2-1 0
}

.woocommerce-terms-and-conditions .lst-kix_list_4-5 > li {
    counter-increment: lst-ctn-kix_list_4-5
}

.woocommerce-terms-and-conditions .lst-kix_list_1-8 > li:before {
    content: "" counter(lst-ctn-kix_list_1-8, lower-roman) ". "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-2 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-2, lower-roman) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_2-3 > li:before {
    content: "(" counter(lst-ctn-kix_list_2-3, upper-latin) ") "
}

.woocommerce-terms-and-conditions .lst-kix_list_4-2 > li {
    counter-increment: lst-ctn-kix_list_4-2
}

.woocommerce-terms-and-conditions ol {
    margin: 0;
    padding: 0
}

.woocommerce-terms-and-conditions table td, table th {
    padding: 0
}

.woocommerce-terms-and-conditions .c9 {
    padding-top: 1pt;
    border-top-width: 0.5pt;
    padding-left: 10.4pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    border-top-style: solid;
    margin-left: 18pt;
    border-top-color: #000000;
    orphans: 2;
    widows: 2;
    text-align: justify
}
.woocommerce-terms-and-conditions .c9_b {
    padding-top: 1pt;
    padding-left: 10.4pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    margin-left: 18pt;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c24 {
    margin-left: 0pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 45pt;
    padding-bottom: 10.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.woocommerce-terms-and-conditions .c7 {
    margin-left: 0pt;
    padding-top: 0pt;
    list-style-position: inside;
    
    padding-bottom: 6.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c15 {
    margin-left: 0pt;
    padding-top: 0pt;
    list-style-position: inside;
    text-indent: 45pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: center
}

.woocommerce-terms-and-conditions .c3 {
    margin-left: 18pt;
    padding-top: 0pt;
    padding-left: 10.4pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c8 {
    margin-left: 46.4pt;
    padding-top: 0pt;
    padding-left: 10.4pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c17 {
    margin-left: 18pt;
    padding-top: 6.5pt;
    padding-left: 17.5pt;
    padding-bottom: 10.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c13 {
    margin-left: 28.4pt;
    padding-top: 0pt;
    text-indent: -28.4pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c1 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Corbel";
    font-style: normal
}

.woocommerce-terms-and-conditions .c2 {
    color: #000000;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9.5pt;
    font-family: "Corbel";
    font-style: normal
}

.woocommerce-terms-and-conditions .c10 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: justify;
    height: 11pt
}

.woocommerce-terms-and-conditions .c22 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9pt;
    font-family: "Corbel";
    font-style: italic
}

.woocommerce-terms-and-conditions .c18 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Lustria";
    font-style: normal
}

.woocommerce-terms-and-conditions .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 9.5pt;
    font-family: "Corbel";
    font-style: normal
}

.woocommerce-terms-and-conditions .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Calibri";
    font-style: normal
}

.woocommerce-terms-and-conditions .c21 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 8.5pt;
    font-family: "Lustria";
    font-style: normal
}

.woocommerce-terms-and-conditions .c25 {
    color: #808080;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Corbel";
    font-style: normal
}

.woocommerce-terms-and-conditions .c23 {
    color: #ff0000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 16pt;
    font-family: "Corbel";
    font-style: normal
}

.woocommerce-terms-and-conditions .c14 {
    padding-top: 0pt;
    padding-bottom: 6.5pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .c16 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: right
}

.woocommerce-terms-and-conditions .c26 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-style: italic
}

.woocommerce-terms-and-conditions .c11 {
    font-size: 9.5pt;
    font-family: "Corbel";
    font-weight: 400
}

.woocommerce-terms-and-conditions .c12 {
    background-color: #ffffff;
    max-width: 484.4pt;
    padding: 56.7pt 56.7pt 42.5pt 70.9pt
}

.woocommerce-terms-and-conditions .c5 {
    padding: 0;
    margin: 0
}

.woocommerce-terms-and-conditions .c6 {
    page-break-after: avoid
}

.woocommerce-terms-and-conditions .c19 {
    height: 11pt
}

.woocommerce-terms-and-conditions .c20 {
    margin-left: 28.4pt
}

.woocommerce-terms-and-conditions .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.woocommerce-terms-and-conditions p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

.woocommerce-terms-and-conditions h1 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 12pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions h2 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 10pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions h3 {
    padding-top: 0pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 10pt;
    font-family: "Calibri";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions h4 {
    padding-top: 0pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 10pt;
    font-family: "Calibri";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions h5 {
    padding-top: 0pt;
    color: #000000;
    font-size: 11pt;
    padding-bottom: 10pt;
    font-family: "Calibri";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: justify
}

.woocommerce-terms-and-conditions h6 {
    padding-top: 10pt;
    color: #1f3863;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Calibri";
    line-height: 1.0;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: justify
}






.importedTermsAndConditions ol{
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px
}

.importedTermsAndConditions .elementor-element .elementor-heading-title {
    color: #49A2F1;
    font-size: 30px;
    font-weight: 400;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 48px;
    letter-spacing: 0px;
}
.importedTermsAndConditions .elementor-element .elementor-heading-title2 {
    color: #262626;
    font-size: 22px;
    font-weight: 500;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 30px;
    -webkit-text-stroke-width: 0.02em;
    stroke-width: 0.02em;
    -webkit-text-stroke-color: #262626;
    stroke: #262626;
}
.importedTermsAndConditions .elementor-element {
    color: #262626;
    font-size: 18px;
    font-weight: 300;
    text-transform: none;
    font-style: normal;
    text-decoration: none;
    line-height: 31px;
    letter-spacing: 0px;
}
.importedTermsAndConditions .elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.importedTermsAndConditions .elementor-element > .elementor-widget-container {
    padding: 2% 0% 0% 0%;
}